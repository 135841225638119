import React, { useEffect, useState, useContext } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  TransactionChart,
  PieChartComponent,
  DashboardCard,
  TableList,
} from "../../components";
import { AuthContext } from "../../contexts/AuthProvider";
import CustomizedSnackbars from "../../components/base/Snackbar";
import moment from "moment";
import api from "../../helpers/api";
import { tableHeadDashboard } from "../../utils/Constants";

const Dashboard = () => {
  const { isLoggedIn, authData } = useContext(AuthContext);
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // ======
  const [productList, setProductList] = useState(null);
  // ==============

  const onGetProductSelling = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .getProductProvit("", "", "")
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
            setIsLoading(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  // useEffect(() => {
  //   // alert(JSON.stringify(isLoggedIn));
  //   // alert(JSON.stringify(authData.data.payload.token));
  //   onGetProductSelling()
  // }, []);

  useEffect(() => {
    const fetch = async () => {
      await onGetProductSelling();
    };
    fetch();
  }, []);

  const onError = async (message) => {
    setProductList([]);
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Dashboard
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Dashboard</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>

      {/* Dashboard Card */}
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        sx={{
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard="Total Balance"
            dataCard="Rp. 3.460.000"
            fromColor={"from-indigo-400"}
            toColor={"to-cyan-400"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total Income"}
            dataCard={"Rp. 3.460.000"}
            fromColor={"from-blue-800"}
            toColor={"to-indigo-900"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total Expenses"}
            dataCard={"Rp. 460.000"}
            fromColor={"from-emerald-500"}
            toColor={"to-emerald-900"}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <DashboardCard
            isLoadingShimmer={isLoading}
            titleCard={"Total User"}
            dataCard={"277"}
            fromColor={"from-amber-500"}
            toColor={"to-pink-500"}
          />
        </Grid>
      </Grid>

      {/* Dashboard Statistic */}
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        sx={{
          marginBottom: 3,
        }}
      >
        <Grid item xs={12} sm={8}>
          <TransactionChart />
        </Grid>

        <Grid item xs={12} sm={4}>
          <PieChartComponent />
        </Grid>
      </Grid>

      {/* heading */}
      <div className="w-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Best Selling Product</h3>
          </div>
        </div>

        {/* table */}
        <TableList
          tableStructure={"best-seller-products"}
          tableName={"Best Seller Products"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadDashboard}
          data={productList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
};

export default Dashboard;
