import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SideBar from "./Sidebar";
import Navbar from "./Navbar";

function MainLayout({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false); // State for drawer open/close

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="app">
        <SideBar />
        <main className="content">
          <Navbar />
          <div className="main-bg overflow-auto max-h-screen pb-24">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}

export default MainLayout;
