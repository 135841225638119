import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadStocOpname } from "../../utils/Constants";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

function StockOpname() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ==================
  const [stockOpnameList, setStockOpnameList] = useState([]);
  const [inventorySelected, setInventorySelected] = useState("");
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================

  const onGetStockOpnameList = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .getInventoryData(branchCodeSelected, searchProductName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setStockOpnameList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ===================
  useEffect(() => {
    const fetch = async () => {
      await onGetStockOpnameList();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isReload) {
      onGetStockOpnameList();
    }
  }, [isReload]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Stock Opname
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Stock Opname</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Stock Opname List</h3>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetStockOpnameList();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="stockout-list"
          tableName={"Stock Opname List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadStocOpname}
          data={stockOpnameList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}

export default StockOpname;
