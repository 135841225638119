import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadCurrentStock } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import moment from "moment";

function CurrentStock() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ==================
  const [currentStockList, setCurrentStockList] = useState(null);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [transactionCode, setTransactionType] = useState("");

  // ===============
  const onGetCurrentStock = async () => {
    setIsLoading(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .getCurrentStock(searchBranchName, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setCurrentStockList(res.data.data);
            setIsLoading(false);
          } else {
            setCurrentStockList([]);
            onError(res.message);
          }
        })
        .catch((error) => {
          setCurrentStockList([]);
          onError(error.message);
        });
    } catch (error) {
      setCurrentStockList([]);
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .reportDownload(transactionCode, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const fileName = "current-stock-report";
            setIsSpinner(false);
            exportToXLSX(res.data.data, fileName);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==============
  useEffect(() => {
    const fetch = async () => {
      await onGetCurrentStock();
    };
    fetch();
  }, []);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  const onError = async (message) => {
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const onSuccessSubmit = async () => {
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Current Stock
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Current Stock</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Current Stock List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={() => {
              onHandleReport();
            }}
          >
            Download Report
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchCodeSelected(v.branchCode);
                } else {
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetCurrentStock();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="current-stock"
          tableName={"Current Stock"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadCurrentStock}
          data={currentStockList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={() => alert("detail")}
        />
      </div>
      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
      <Spinner isShowSpinner={isSpinner} />
    </div>
  );
}

export default CurrentStock;

// import React, { useEffect, useState } from "react";
// import api from "../../helpers/api";
// import { useTheme } from "@mui/material/styles";
// import { SearchOutlined, SendOutlined } from "@mui/icons-material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { TableData, FormDatePicker } from "../../components";
// import Autocomplete from "@mui/material/Autocomplete";
// import {
//   IconButton,
//   Button,
//   TextField,
//   InputAdornment,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Typography,
//   Grid,
//   Box,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Paper,
//   Divider,
// } from "@mui/material";
// import { generateCorrelationId } from "../../helpers/functions/generateRandomChar";
// import CustomizedSnackbars from "../../components/base/Snackbar";
// import { validateFormInputSupplier } from "../../helpers/functions/validateTextInput";
// import { FormInput, Modal } from "../../components";
// import { CloseOutlined } from "@mui/icons-material";
// import NumberFormat from "../../helpers/functions/numberFormat";
// import exportFromJSON from "export-from-json";
// import moment from "moment";

// const tableHeadTransaction = [
//   { id: "transactionNo", name: "Transaction No" },
//   { id: "branchName", name: "Branch Name" },
//   { id: "itemsTransaction", name: "Items Transaction" },
//   { id: "orderCost", name: "Order Cost" },
//   { id: "transactionDate", name: "Transaction Date" },
//   { id: "status", name: "Status" },
//   { id: "action", name: "Action" },
// ];

// const statusList = [
//   {
//     id: 1,
//     label: "Income",
//     value: "Income",
//   },
//   {
//     id: 2,
//     label: "Expenses",
//     value: "Expenses",
//   },
// ];

// export default function CurrentStock() {
//   const theme = useTheme();
//   const [isLoading, setIsLoading] = useState(true);
//   const [isReload, setIsReload] = useState(false);
//   const [isOpenModal, setIsOpenModal] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [mode, setMode] = useState("");
//   const [transactionCode, setTransactionType] = useState("");
//   const [statusSelected, setStatusSelected] = useState([]);

//   // ===========
//   const [transactionList, setTransactionList] = useState([]);
//   const [searchTransactionNo, setSearchTransactionNo] = useState("");
//   // ===========
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [page, setPage] = useState(0);
//   // ==========
//   const [isOpen, setIsOpen] = useState(false);
//   const [alertType, setAlertType] = useState("");
//   const [message, setMessage] = useState("");
//   // ==========
//   const [branchList, setBranchList] = useState([]);
//   const [searchBranchName, setSearchBranchName] = useState("");
//   const [branchNameFromSelected, setBranchNameFromSelected] = useState([]);
//   const [branchNameToSelected, setBranchNameToSelected] = useState([]);
//   const [branchCodeSelected, setBranchCodeSelected] = useState("");
//   // ==========

//   const [transactionSelected, setTransactionSelected] = useState([]);
//   const [detailTransaction, setDetailTransaction] = useState([]);
//   const [isVisibleDetail, setIsVisibleDetail] = useState(false);
//   // ==========
//   const [selectedDateFrom, setSelectedDateFrom] = useState(null);
//   const [selectedDateTo, setSelectedDateTo] = useState(null);
//   // ==========

//   const onGetTransaction = async () => {
//     const correlationId = generateCorrelationId();
//     const dateFromSelected =
//       selectedDateFrom === null
//         ? ""
//         : moment(selectedDateFrom).format("YYYY-MM-DD");
//     const dateToSelected =
//       selectedDateTo === null
//         ? ""
//         : moment(selectedDateTo).format("YYYY-MM-DD");
//     setIsLoading(true);
//     try {
//       const res = await api
//         .getCurrentStock(searchBranchName, dateFromSelected, dateToSelected, {
//           headers: { "X-Correlation-Id": correlationId },
//         })
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setTransactionList(res.data.data);
//             setIsLoading(false);
//             setIsReload(false);
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   const onGetBranches = async () => {
//     const correlationId = generateCorrelationId();
//     try {
//       const res = await api
//         .getBranch(searchBranchName, {
//           headers: { "X-Correlation-Id": correlationId },
//         })
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setBranchList(res.data.data);
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   const onGetDetailTransaction = async (data) => {
//     try {
//       const res = await api
//         .getDetailTransaction(data.id)
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             setDetailTransaction(res.data);
//             setTransactionSelected(data);
//             setIsVisibleDetail(true);
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   const onHandleReport = async () => {
//     const dateFromSelected =
//       selectedDateFrom === null
//         ? ""
//         : moment(selectedDateFrom).format("YYYY-MM-DD");
//     const dateToSelected =
//       selectedDateTo === null
//         ? ""
//         : moment(selectedDateTo).format("YYYY-MM-DD");
//     try {
//       const res = await api
//         .reportDownload(transactionCode, dateFromSelected, dateToSelected)
//         .then(async (res) => {
//           if (res.statusCode === 200) {
//             const fileName = "orders-transaction-report";
//             const exportType = exportFromJSON.types.xls;
//             exportFromJSON({ data: res.data.data, fileName, exportType });
//             onSuccessSubmit();
//           } else {
//             onError(res.message);
//           }
//         })
//         .catch((error) => {
//           onError(error.message);
//         });
//     } catch (error) {
//       onError(error.message);
//     }
//   };

//   // ==========

//   useEffect(() => {
//     const fetch = async () => {
//       await onGetTransaction();
//     };
//     fetch();
//   }, []);

//   useEffect(() => {
//     if (isReload) {
//       onGetTransaction();
//     }
//   }, [isReload]);

//   useEffect(() => {
//     onGetBranches();
//   }, [searchBranchName]);

//   // ==========

//   const onSuccessSubmit = async () => {
//     setAlertType("success");
//     setMessage("Successfully...");
//   };

//   const onError = async (message) => {
//     setIsLoading(false);
//     setIsOpen(true);
//     setAlertType("error");
//     setMessage(message);
//     setIsReload(false);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const handlePreviewDetail = (itemSelected, indexSelected) => {
//     onGetDetailTransaction(itemSelected);
//   };

//   const handleEditItem = (itemSelected, indexSelected) => {
//     setTransactionSelected(itemSelected);
//     setMode("edit");
//     setIsOpenModal(true);
//   };

//   // ==========

//   return (
//     <>
//       <div className="flex justify-center p-6">
//         <div className="bg-white rounded-md px-5 h-full w-full shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
//           <div className="flex flex-col">
//             <div className="flex flex-row justify-between border-b py-6">
//               <div className="flex flex-row items-center gap-2">
//                 {/* <PiCubeDuotone className="text-2xl" /> */}
//                 <h1 className="text-2xl font-bold text-black">Current stock</h1>
//               </div>
//               <Button
//                 component="label"
//                 role={undefined}
//                 variant="contained"
//                 tabIndex={-1}
//                 startIcon={<CloudUploadIcon />}
//                 onClick={() => {
//                   onHandleReport();
//                 }}
//               >
//                 Download Report
//               </Button>
//             </div>
//             <Grid
//               container
//               spacing={1}
//               alignItems={"center"}
//               sx={{
//                 margin: 0,
//               }}
//             >
//               <Grid item xs={12} sm={3}>
//                 <Autocomplete
//                   options={branchList}
//                   onInputChange={(event, newInputValue) => {
//                     setSearchBranchName(event.target.value);
//                   }}
//                   onChange={(e, v) => {
//                     if (v) {
//                       setSearchBranchName(v.branchCode);
//                     } else {
//                       setSearchBranchName("");
//                       setIsReload(true);
//                     }
//                   }}
//                   getOptionLabel={(branchList) => branchList.branchName || ""}
//                   componentsProps={{
//                     popper: {
//                       modifiers: [
//                         {
//                           name: "flip",
//                           enabled: false,
//                         },
//                         {
//                           name: "preventOverflow",
//                           enabled: false,
//                         },
//                       ],
//                     },
//                     paper: {
//                       style: { maxHeight: "150px", overflowY: "auto" }, // Set max-height and overflow
//                     },
//                   }}
//                   renderInput={(params) => (
//                     <TextField
//                       {...params}
//                       size="small"
//                       label="Choose Branch Location"
//                       placeholder="Search your branch location..."
//                       error={!!errors.branchNameFromSelected}
//                       helperText={errors.branchNameFromSelected}
//                     />
//                   )}
//                   sx={{
//                     mt: 2,
//                     mb: 2,
//                   }}
//                 />
//               </Grid>

//               <Grid item xs={12} sm={3}>
//                 <FormDatePicker
//                   labelFormDatePicker="Date From"
//                   dateValue={selectedDateFrom}
//                   onChangeDate={(val) => setSelectedDateFrom(val)}
//                 />
//               </Grid>

//               <Grid item xs={12} sm={3}>
//                 <FormDatePicker
//                   labelFormDatePicker="Date To"
//                   dateValue={selectedDateTo}
//                   onChangeDate={(val) => setSelectedDateTo(val)}
//                 />
//               </Grid>

//               <Grid item xs={12} sm={3}>
//                 <Button
//                   component="label"
//                   role={undefined}
//                   variant="contained"
//                   tabIndex={-1}
//                   startIcon={<SendOutlined />}
//                   onClick={() => {
//                     setIsReload(true);
//                   }}
//                   sx={{
//                     ml: 2,
//                   }}
//                 >
//                   Search
//                 </Button>
//               </Grid>
//             </Grid>
//             <TableData
//               tableName={"Tansaction"}
//               dataStructure={"inventory"}
//               tableHead={tableHeadTransaction}
//               data={transactionList}
//               isLoading={isLoading}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               handleChangePage={handleChangePage}
//               handleChangeRowsPerPage={handleChangeRowsPerPage}
//               onPreviewDetailItem={handlePreviewDetail}
//               onEditItem={handleEditItem}
//             />
//           </div>
//         </div>
//       </div>

//       <Dialog fullWidth maxWidth="md" open={isVisibleDetail}>
//         <DialogTitle sx={{ mb: 2 }}>Detail Transaction</DialogTitle>
//         <DialogContent>
//           <DialogContentText variant="body2">
//             <Grid container spacing={1}>
//               <Grid item xs={12} sm={12}>
//                 <Typography variant="subtitle1">Amount</Typography>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     gap: 2,
//                   }}
//                 >
//                   <Typography variant="h5" color="primary">
//                     {`Rp. ${NumberFormat(0, transactionSelected.netPayment)}`}
//                   </Typography>
//                   <Box
//                     display="flex"
//                     alignItems="center"
//                     px={2}
//                     sx={{
//                       backgroundColor: `${
//                         transactionSelected.statusName === "Done"
//                           ? "green"
//                           : "red"
//                       }`,
//                       borderRadius: 8,
//                     }}
//                   >
//                     <Typography variant="caption" color="common.white">
//                       {transactionSelected.statusName}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="subtitle1">Transaction No:</Typography>
//                 <Typography variant="body2">
//                   {transactionSelected.transactionNo}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="subtitle1">Transaction Date:</Typography>
//                 <Typography variant="body2">
//                   {moment(transactionSelected.transactionDate).format(
//                     "DD MMM YYYY, h:mm"
//                   )}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="subtitle1">Transaction Type:</Typography>
//                 <Typography variant="body2">
//                   {transactionSelected.transactionType}
//                 </Typography>
//               </Grid>
//             </Grid>
//             <Typography variant="h6" sx={{ mt: 4 }}>
//               Transaction Details
//             </Typography>
//             <Divider
//               sx={{
//                 paddingTop: "5px",
//               }}
//             />
//             <Grid sx={{ padding: "10px" }} container spacing={2}>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="body1">Transaction From</Typography>
//                 <Typography variant="body2">
//                   {transactionSelected.transactionFromName}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="body1">Transaction To</Typography>
//                 <Typography variant="body2">
//                   {transactionSelected.transactionToName}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <Typography variant="body1">Items</Typography>
//                 <Typography variant="body2">
//                   {`${NumberFormat(0, transactionSelected.item)} Items`}
//                 </Typography>
//               </Grid>
//             </Grid>
//             {detailTransaction?.attachments && (
//               <>
//                 <Typography variant="h6" sx={{ mt: 4 }}>
//                   Items
//                 </Typography>
//                 <TableContainer>
//                   <Table sx={{ minWidth: 650 }}>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell align="center">No</TableCell>
//                         <TableCell>Product Code</TableCell>
//                         <TableCell>Product Name</TableCell>
//                         <TableCell>Brand</TableCell>
//                         <TableCell>Category</TableCell>
//                         <TableCell align="center">Quantity</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {detailTransaction.attachments.map((itemData, index) => (
//                         <TableRow key={itemData.id}>
//                           <TableCell align="center">{index + 1}</TableCell>
//                           <TableCell>{itemData.productCode}</TableCell>
//                           <TableCell>{itemData.productName}</TableCell>
//                           <TableCell>{itemData.brandName}</TableCell>
//                           <TableCell>{itemData.categoryName}</TableCell>
//                           <TableCell align="center">
//                             {`${NumberFormat(0, itemData.quantity)} pcs`}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//               </>
//             )}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions sx={{ mb: 2, mr: 2 }}>
//           <Button onClick={() => setIsVisibleDetail(false)}>Close</Button>
//         </DialogActions>
//       </Dialog>

//       <CustomizedSnackbars
//         isOpen={isOpen}
//         typeInfo={alertType}
//         messageInfo={message}
//         handleCloseSnackbar={() => setIsOpen(false)}
//       />
//     </>
//   );
// }
