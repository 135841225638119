import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadProductReceive } from "../../utils/Constants";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import ModalDetail from "../../components/ModalDetail";

function ProductReceipt() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transactionCode, setTransactionType] = useState(10);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [productReceiveList, setProductReceiveList] = useState([]);
  const [searchPurchaseNo, setSearchPurchaseNo] = useState("");
  // ===============
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);

  const onGetProductReceive = async () => {
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    setIsLoading(true);
    try {
      const res = await api
        .getPurchaseDataList(
          page,
          rowsPerPage,
          searchPurchaseNo,
          dateFromSelected,
          dateToSelected
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductReceiveList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetDetailTransaction = async (data) => {
    try {
      const res = await api
        .getDetailTransaction(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const finishPurchaseOrder = async (data) => {
    setIsVisibleDetail(false);
    setIsSpinner(true);
    try {
      const res = await api
        .finishPurchaseOrder(detailTransaction, data)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsVisibleDetail(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsVisibleDetail(true);
          onError(error.message);
        });
    } catch (error) {
      setIsVisibleDetail(true);
      onError(error.message);
    }
  };

  const onCancelPurchase = async () => {
    setIsVisibleDetail(false);
    setIsSpinner(true);
    try {
      const res = await api
        .cancelPurchases(detailTransaction)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsVisibleDetail(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsVisibleDetail(true);
          onError(error.message);
        });
    } catch (error) {
      setIsVisibleDetail(true);
      onError(error.message);
    }
  };

  const onHandleReport = async () => {
    setIsSpinner(true);
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    try {
      const res = await api
        .reportDownload(transactionCode, dateFromSelected, dateToSelected)
        .then(async (res) => {
          if (res.statusCode === 200) {
            const fileName = "product-receive-report";
            setIsSpinner(false);
            exportToXLSX(res.data.data, fileName);
            onSuccessSubmit();
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ================

  useEffect(() => {
    const fetch = async () => {
      await onGetProductReceive();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isReload) {
      onGetProductReceive();
    }
  }, [isReload]);

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const onSuccessSubmit = async () => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    onGetDetailTransaction(itemSelected);
  };

  const onHandleFinishOrder = async () => {
    const newArray = detailTransaction.attachments;
    await newArray.forEach((item) => {
      item.updatedQuantity = item.quantity;
    });
    // console.log("finish order", newArray);
    finishPurchaseOrder(newArray);
  };

  // =============

  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Product Receive
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Product Receive</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>

      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Product Receive List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<DownloadForOfflineIcon />}
            onClick={() => {
              onHandleReport();
            }}
          >
            Download Report
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetProductReceive();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="product-receive-list"
          tableName={"Product Receive List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadProductReceive}
          data={productReceiveList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
      <ModalDetail
        isAmount={true}
        isProductReceive={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        transactionSelected={transactionSelected}
        detailTransaction={detailTransaction}
        onCancel={() => onCancelPurchase()}
        onHandleFinishOrder={() => onHandleFinishOrder()}
      />
    </div>
  );
}

export default ProductReceipt;
