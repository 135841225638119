import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import { tableHeadPurchases, tableHeadPO } from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ModalDetail from "../../components/ModalDetail";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { exportToXLSX } from "../../helpers/functions/generateExcel";

function Purchases() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ==================
  const [purchaseList, setPurchaseList] = useState([]);
  const [searchPurchaseNo, setSearchPurchaseNo] = useState("");
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  // ==================
  const [supplierList, setSupplierList] = useState([]);
  const [searchSupplierName, setSearchSupplierName] = useState("");
  const [supplierNameSelected, setSupplierNameSelected] = useState([]);
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  // ==================
  const [productList, setProductList] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  // ==================
  const [variantList, setVariantList] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  // ==================
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [notes, setNotes] = useState("");
  const [productOrders, setProductOrders] = useState([]);
  const [additional, setAdditional] = useState({
    serviceCharge: "0",
    taxCharge: "0",
    taxResto: "0",
    cashPayment: 0,
    edcPayment: 0,
    rounding: 0,
    netPayment: 0,
    discount: 0,
    additionalCost: 0,
    paymentType: "Cash",
    paymentTypeCode: 456,
    transactionType: "PURCHASE ORDER",
    transactionTypeCode: 10,
  });
  // ==================

  const onGetPurchases = async () => {
    const dateFromSelected =
      selectedDateFrom === null
        ? ""
        : moment(selectedDateFrom).format("YYYY-MM-DD");
    const dateToSelected =
      selectedDateTo === null
        ? ""
        : moment(selectedDateTo).format("YYYY-MM-DD");
    setIsLoading(true);
    try {
      const res = await api
        .getPurchaseDataList(
          page,
          rowsPerPage,
          searchPurchaseNo,
          dateFromSelected,
          dateToSelected
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setPurchaseList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetVariantProduct = async (masterProductCode) => {
    try {
      const res = await api
        .getProductVariantList(masterProductCode)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setVariantList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetSupplier = async () => {
    try {
      const res = await api
        .getSupplier(searchSupplierName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setSupplierList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetProducts = async () => {
    try {
      const res = await api
        .getProduct(
          searchProductName,
          branchNameSelected.length !== 0 ? branchNameSelected.branchCode : ""
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setProductList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const getDetailPurchase = async (data) => {
    setIsSpinner(true);
    try {
      const res = await api
        .getDetailPurchase(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const savePurchaseOrder = async () => {
    const sumTotal = productOrders.reduce(
      (accumulator, current) => accumulator + parseInt(current.netPrice),
      0
    );
    setIsOpenModal(false);
    setIsSpinner(true);
    const bodyPo = {
      item: productOrders.length,
      note: notes,
      TaCharge: additional.taxCharge,
      discount: additional.discount,
      rounding: additional.rounding,
      taxResto: additional.taxResto,
      edcPayment: additional.edcPayment,
      netPayment: sumTotal,
      cashPayment: sumTotal,
      paymentType: additional.paymentType,
      serviceCharge: additional.serviceCharge,
      additionalCost: additional.additionalCost,
      paymentTypeCode: additional.paymentTypeCode,
      transactionType: additional.transactionType,
      transactionToCode: branchNameSelected.branchCode,
      transactionToName: branchNameSelected.branchName,
      transactionFromCode: supplierNameSelected.supplierCode,
      transactionFromName: supplierNameSelected.supplierName,
      transactionTypeCode: additional.transactionTypeCode,
      attachments: productOrders,
    };
    console.log("bodyPo", bodyPo);
    try {
      const res = await api
        .savePurchaseOrder(
          productOrders.length,
          notes,
          additional.taxCharge,
          additional.discount,
          additional.rounding,
          additional.taxResto,
          additional.edcPayment,
          sumTotal,
          sumTotal,
          additional.paymentType,
          additional.serviceCharge,
          additional.additionalCost,
          additional.paymentTypeCode,
          additional.transactionType,
          branchNameSelected.branchCode,
          branchNameSelected.branchName,
          supplierNameSelected.supplierCode,
          supplierNameSelected.supplierName,
          additional.transactionTypeCode,
          productOrders
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  // ==================

  useEffect(() => {
    const fetch = async () => {
      await onGetPurchases();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isReload) {
      onGetPurchases();
    }
  }, [isReload]);

  useEffect(() => {
    onGetSupplier();
  }, [searchSupplierName]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetProducts();
  }, [searchProductName]);

  useEffect(() => {
    if (resetForm) {
      setResetForm(false);
    }
  }, [resetForm]);

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const onSuccessSubmit = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setSupplierNameSelected([]);
    setSearchSupplierName("");
    setNotes("");
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
  };

  const onCloseModal = async () => {
    setBranchNameSelected([]);
    setSearchBranchName("");
    setSupplierNameSelected([]);
    setSearchSupplierName("");
    setNotes("");
    setProductOrders([]);
    setProductNameSelected([]);
    setSearchProductName("");
    setVariantNameSelected([]);
    setSearchVariantName("");
    setMode("");
    setErrors({});
    setIsOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        note: "",
        brandId: 0,
        masterProductCode: "",
        variantName: "",
        imageUrl: "",
        netPrice: 0,
        quantity: 0,
        trxPrice: 0,
        brandName: "",
        productId: "",
        categoryId: 0,
        productSku: "",
        productCode: "",
        productName: "",
        categoryName: "",
        updatedQuantity: 0,
      },
    ]);
  };

  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    const newProducts = [...productOrders];
    newProducts[currentIndex].note = "";
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].productId = itemSelector.id;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].updatedQuantity = itemSelector.updatedQuantity;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;

    newProducts[currentIndex].variantName = "";
    newProducts[currentIndex].productSku = "";
    newProducts[currentIndex].productCode = "";

    newProducts[currentIndex].netPrice = 0;
    newProducts[currentIndex].quantity = 0;
    newProducts[currentIndex].trxPrice = 0;

    setProductOrders(newProducts);
    setVariantNameSelected((prevData) => {
      const updatedData = [...prevData];
      updatedData[currentIndex] = {
        ...updatedData[currentIndex],
        variantSelected: [],
        searchVariant: "",
      };
      return updatedData;
    });
  };

  const onHandleVariantItemSelected = (currentIndex, itemSelector) => {
    const duplicateProduct = productOrders.filter(
      (x) => x.productId === itemSelector.id
    );
    if (duplicateProduct.length == 0) {
      const newProducts = [...productOrders];
      newProducts[currentIndex].variantName = itemSelector.variantName;
      newProducts[currentIndex].productSku = itemSelector.productSku;
      newProducts[currentIndex].productCode = itemSelector.productCode;
      setProductOrders(newProducts);
    }
  };

  const handleInputQuantityChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    newProducts[index].updatedQuantity = event.target.value;
    setProductOrders(newProducts);
  };

  const handleInputChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    setProductOrders(newProducts);
  };

  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].netPrice =
      newProducts[index].quantity * newProducts[index].trxPrice;
    setProductOrders(newProducts);
  };

  const calculatePriceItem = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].trxPrice =
      newProducts[index].netPrice / newProducts[index].quantity;
    setProductOrders(newProducts);
  };

  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    newArray.splice(index, 1);
    setProductOrders(newArray);
    setSearchProductName("");
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !supplierNameSelected ||
      supplierNameSelected === null ||
      supplierNameSelected.length === 0
    ) {
      newErrors.supplierNameSelected = "Supplier is required to choose";
    }

    if (
      !branchNameSelected ||
      branchNameSelected === null ||
      branchNameSelected.length === 0
    ) {
      newErrors.branchNameSelected = "Branch / Outlet is required to choose";
    }
    return newErrors;
  };

  const validateFormProductOrder = () => {
    const hasEmptyFields = productOrders.some(
      (productItem) =>
        !productItem.masterProductCode ||
        !productItem.variantName ||
        !productItem.quantity ||
        !productItem.trxPrice ||
        !productItem.netPrice
    );

    if (productOrders.length === 0) {
      onError("Product Order must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all product order have Product Name, Variant Name, Quantity, Price & Total filled."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const isValid = validateFormProductOrder();
    if (isValid) {
      savePurchaseOrder();
    }
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    getDetailPurchase(itemSelected);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Purchases
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Purchases List</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">Purchases List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Add New Purchases
          </Button>
        </div>

        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE FROM</h4>
            <FormDatePicker
              labelFormDatePicker="Date From"
              dateValue={selectedDateFrom}
              onChangeDate={(val) => setSelectedDateFrom(val)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <h4 className="font-semibold mb-2 text-xs">DATE TO</h4>
            <FormDatePicker
              labelFormDatePicker="Date To"
              dateValue={selectedDateTo}
              onChangeDate={(val) => setSelectedDateTo(val)}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                onGetPurchases();
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="purchases-list"
          tableName={"Purchases List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadPurchases}
          data={purchaseList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div>

      <Modal
        fullScreen={true}
        modalTitle={"Add New Purchase Order"}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Purchase Order"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={supplierNameSelected}
              dataList={supplierList}
              onInputChange={(event, newInputValue) => {
                setSearchSupplierName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setSupplierNameSelected(v);
                } else {
                  setSupplierNameSelected("");
                }
              }}
              getOptionLabel={(supplierList) => supplierList.supplierName || ""}
              labelPicker={"Choose Supplier"}
              placeholderPicker={"search supplier"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.supplierNameSelected}
              helperText={errors.supplierNameSelected}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameSelected(v);
                } else {
                  setBranchNameSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet"}
              placeholderPicker={"search branch / outlet"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameSelected}
              helperText={errors.branchNameSelected}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              label="Note"
              name="notes"
              error={!!errors.notes}
              helperText={errors.notes}
              value={notes}
              onChange={(event) => {
                setNotes(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              sx={{ marginTop: "15px", marginBottom: "15px" }}
              variant="outlined"
              onClick={() => handleAddProduct()}
              startIcon={<AddCircleIcon />}
            >
              Add Product
            </Button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadPO.map((item) => {
                      return (
                        <TableCell
                          // colSpan={item.size}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                            width: item.size,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productOrders.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableHeadPO.length}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <AddCircleIcon color="secondary" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {` No product found. You can add a new product using the
                      button above.`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {productOrders.map((item, index) => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell width={"5%"}>{index + 1}</TableCell>
                            <TableCell width={"25%"}>
                              <PickerSearch
                                dataList={productList}
                                onInputChange={(event, newInputValue) => {
                                  setSearchProductName(newInputValue);
                                }}
                                onChange={(e, v) => {
                                  if (v) {
                                    setProductNameSelected(v);
                                    setSearchProductName("");
                                    setSearchVariantName("");
                                    onHandleProductItemSelected(index, v);
                                  } else {
                                    setResetForm(true);
                                    setProductNameSelected([]);
                                    setVariantList([]);
                                    setSearchProductName("");
                                    setSearchVariantName("");
                                    setVariantNameSelected((prevData) => {
                                      const updatedData = [...prevData];
                                      updatedData[index] = {
                                        ...updatedData[index],
                                        variantSelected: [],
                                        searchVariant: "",
                                      };
                                      return updatedData;
                                    });
                                    setProductOrders((prevData) => {
                                      const updatedData = [...prevData];
                                      updatedData[index] = {
                                        ...updatedData[index],
                                        masterProductCode: "",
                                      };
                                      return updatedData;
                                    });
                                  }
                                }}
                                getOptionLabel={(productList) =>
                                  productList.productName || ""
                                }
                                labelPicker={"Choose Product"}
                                placeholderPicker={"search product"}
                                sizePicker={"small"}
                                isVisibleLabelPicker={true}
                                labelTitlePicker={""}
                                error={!!errors.productNameSelected}
                                helperText={errors.productNameSelected}
                              />
                            </TableCell>
                            <TableCell width={"25%"}>
                              {resetForm ? (
                                <></>
                              ) : (
                                <PickerSearch
                                  disabled={
                                    productOrders[index].masterProductCode
                                      ? false
                                      : true
                                  }
                                  value={
                                    variantNameSelected[index]?.variantSelected
                                  }
                                  dataList={variantList}
                                  onInputChange={(event, newInputValue) => {
                                    setSearchVariantName(newInputValue);
                                  }}
                                  onChange={(e, v) => {
                                    if (v) {
                                      setVariantNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          variantSelected: v,
                                          searchVariant: v.variantName,
                                        };
                                        return updatedData;
                                      });
                                      onHandleVariantItemSelected(index, v);
                                    } else {
                                      setVariantNameSelected((prevData) => {
                                        const updatedData = [...prevData];
                                        updatedData[index] = {
                                          ...updatedData[index],
                                          variantSelected: [],
                                          searchVariant: "",
                                        };
                                        return updatedData;
                                      });
                                      setSearchVariantName("");
                                    }
                                  }}
                                  getOptionLabel={(variantList) =>
                                    variantList.variantName || ""
                                  }
                                  onOpen={() =>
                                    onGetVariantProduct(
                                      productOrders[index].masterProductCode
                                    )
                                  }
                                  labelPicker={"Choose Variant"}
                                  placeholderPicker={"search variant"}
                                  sizePicker={"small"}
                                  isVisibleLabelPicker={true}
                                  labelTitlePicker={""}
                                  error={!!errors.variantNameSelected}
                                  helperText={errors.variantNameSelected}
                                />
                              )}
                            </TableCell>
                            <TableCell width={"10%"}>
                              <FormInput
                                disabled={
                                  productOrders[index].masterProductCode
                                    ? false
                                    : true
                                }
                                size="small"
                                label="Quantity"
                                name="quantity"
                                type="number"
                                error={!!errors.quantity}
                                helperText={errors.quantity}
                                value={item.quantity}
                                onChange={(event) => {
                                  handleInputQuantityChange(event, index);
                                  calculateTotalPrice(index);
                                }}
                              />
                            </TableCell>
                            <TableCell width={"15%"}>
                              <FormInput
                                disabled={
                                  productOrders[index].masterProductCode
                                    ? false
                                    : true
                                }
                                size="small"
                                label="Price"
                                name="trxPrice"
                                type="number"
                                inputPrice={true}
                                error={!!errors.trxPrice}
                                helperText={errors.trxPrice}
                                value={item.trxPrice}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                  calculateTotalPrice(index);
                                }}
                              />
                            </TableCell>
                            <TableCell width={"15%"}>
                              <FormInput
                                disabled={
                                  productOrders[index].masterProductCode
                                    ? false
                                    : true
                                }
                                size="small"
                                label="Total"
                                name="netPrice"
                                type="number"
                                inputPrice={true}
                                error={!!errors.netPrice}
                                helperText={errors.netPrice}
                                value={item.netPrice}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                  calculatePriceItem(index);
                                }}
                              />
                            </TableCell>
                            <TableCell width={"5%"} align="center">
                              <div className="flex flex-row gap-2 items-center justify-center">
                                <Tooltip title="Delete Variant">
                                  <IconButton
                                    onClick={() =>
                                      onHandleRemoveProductOrder(index)
                                    }
                                    size="medium"
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 1,
                                      backgroundColor: "#f4e6fb",
                                      color: "#FE3133",
                                    }}
                                  >
                                    <BsFillTrash3Fill />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Modal>

      <ModalDetail
        isAmount={true}
        fullScreen={true}
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        transactionSelected={transactionSelected}
        detailTransaction={detailTransaction}
      />

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </div>
  );
}

export default Purchases;
