import React, { useEffect, useState, useCallback } from "react";
import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { CloseOutlined } from "@mui/icons-material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

function FileUpload({ onUpload, initialFiles = [] }) {
  const [files, setFiles] = useState(initialFiles); // Use initialFiles prop

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length) {
        setFiles((prevFiles) => [
          //   ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          ),
        ]);
        // Call onUpload prop with accepted files (optional)
        if (onUpload) {
          onUpload(acceptedFiles);
        }
      }
      console.log("file input", acceptedFiles);
    },
    [onUpload]
  );

  const removeFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [], // Adjust accepted file types as needed
    },
    maxSize: 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    // Cleanup function to revoke object URLs when the component unmounts
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: "1px dashed #ccc", // Adjust color and size as needed
          padding: "16px", // Add padding for content
          borderRadius: "4px", // Optional rounded corners
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <input {...getInputProps()} />
        <Typography variant="body2" color="text.secondary">
          Image Product
        </Typography>
        <IconButton color="primary">
          <DriveFolderUploadIcon fontSize="large" />
        </IconButton>
        {isDragActive ? (
          <Typography variant="body2" color="text.secondary">
            Drop the files here ...
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            Drag & drop files here, or click to select files
          </Typography>
        )}
      </Box>

      <List className="-mt-2 mb-10 border border-gray-100 rounded-md">
        {files.map((file) => (
          <ListItem
            key={file.name}
            sx={{
              backgroundColor: "#FAFAFA",
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
              width: 150,
              borderRadius: 1,
            }}
          >
            <ListItemAvatar
              sx={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                paddingTop: 2,
              }}
            >
              <Avatar
                src={file.preview}
                alt={file.name}
                variant="square"
                sx={{ width: 100, height: 100, objectFit: "contain" }}
                onLoad={() => URL.revokeObjectURL(file.preview)}
              />
            </ListItemAvatar>
            <ListItemText primary={file.name} />
            <IconButton
              // className="w-7 h-7 border border-slate-400 bg-secondary-400 rounded-full flex justify-center items-center absolute -top-3 -right-3 hover:bg-white transition-colors"
              sx={{
                width: 5,
                height: 5,
                position: "absolute",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                backgroundColor: "#FE3133",
                color: "white",
                top: -3,
                right: -3,
                padding: 1.5,
              }}
              onClick={() => removeFile(file.name)}
            >
              <CloseOutlined fontSize="small" />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default FileUpload;
