import React, { useState, useContext } from "react";
import AuthContext from "../../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { validateFormInput } from "../../helpers/functions/validateTextInput";
import { FormInput } from "../../components";
import api from "../../helpers/api";
import { generateCorrelationId } from "../../helpers/functions/generateRandomChar";
import CustomizedSnackbars from "../../components/base/Snackbar";

const defaultTheme = createTheme();

export default function Login() {
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("akbar@admin.com");
  const [password, setPassword] = useState("123456");
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");

  // ============

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setErrors({});
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};

    if (!username || username.trim() === "") {
      newErrors.username = "Username is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
      newErrors.username = "Invalid username format";
    }

    if (!password || password.trim() === "") {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await validateFormInput(username, password);
      const correlationId = generateCorrelationId();

      setIsLoading(true);
      const res = await api
        .onLogin(username, password, {
          headers: { "X-Correlation-Id": correlationId },
        })
        .then(async (res) => {
          if (res.statusCode === 200) {
            const data = res.data;
            onSuccessSubmit(data);
            setIsLoading(false);
          } else {
            onErrorSubmit(res.message);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          onErrorSubmit(error.message);
          setErrors({ general: "An error occurred. Please try again." });
          setIsLoading(false);
        });
    } catch (error) {
      onErrorSubmit(error.message);
      setErrors({ general: "An error occurred. Please try again." });
      setIsLoading(false);
    }
  };

  const onSuccessSubmit = async (data) => {
    const accessToken = data.payload;
    const users = data.data;
    setAuthData({ data });
    localStorage.setItem("isLoggedIn", true);
    setIsLoading(false);
    navigate("/");
  };

  const onErrorSubmit = async (message) => {
    setIsLoading(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  // =============

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          className="flex justify-center items-center"
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log In
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <FormInput
                label="Username"
                name="username"
                error={!!errors.username}
                helperText={errors.username}
                value={username}
                onChange={handleUsernameChange}
              />
              <FormInput
                label="Password"
                name="password"
                type="password"
                error={!!errors.password}
                helperText={errors.password}
                value={password}
                onChange={handlePasswordChange}
              />
              <LoadingButton
                className={`${
                  isLoading ? "bg-gray-400 py-3" : "bg-primary py-3"
                }`}
                type="submit"
                fullWidth
                loading={isLoading}
                loadingPosition="end"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <span>Log In</span>
              </LoadingButton>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                className="mt-5"
              >
                {"Copyright © "}
                <span>Bottle Garden</span> {new Date().getFullYear()}
                {". "}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />
    </ThemeProvider>
  );
}
