import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  TableList,
  PickerSearch,
  FormDatePicker,
  Spinner,
  Modal,
  FormInput,
} from "../../components";
import CustomizedSnackbars from "../../components/base/Snackbar";
import api from "../../helpers/api";
import {
  tableHeadStockIn,
  tableHeadTransferProduct,
} from "../../utils/Constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ModalDetail from "../../components/ModalDetail";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { exportToXLSX } from "../../helpers/functions/generateExcel";
import NumberFormat from "../../helpers/functions/numberFormat";

function Inventory() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  // ==================
  const [isOpen, setIsOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("");
  // ==================
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  // ==================
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // ==================
  const [inventoryList, setInventoryList] = useState([]);
  const [searchProductName, setSearchProductName] = useState("");
  const [searchInventoryLocation, setSearchInventoryLocation] = useState("");
  // ==================
  const [branchList, setBranchList] = useState([]);
  const [searchBranchName, setSearchBranchName] = useState("");
  const [branchNameSelected, setBranchNameSelected] = useState([]);
  const [branchCodeSelected, setBranchCodeSelected] = useState("");
  // ==================
  const [branchNameFromSelected, setBranchNameFromSelected] = useState([]);
  const [branchNameToSelected, setBranchNameToSelected] = useState([]);
  // ==================
  const [productOrders, setProductOrders] = useState([]);
  const [productNameSelected, setProductNameSelected] = useState([]);
  // ==================
  const [variantList, setVariantList] = useState([]);
  const [variantNameSelected, setVariantNameSelected] = useState([]);
  const [searchVariantName, setSearchVariantName] = useState("");
  // ==================
  const [transactionSelected, setTransactionSelected] = useState([]);
  const [detailTransaction, setDetailTransaction] = useState([]);
  const [isVisibleDetail, setIsVisibleDetail] = useState(false);

  const onGetInventory = async () => {
    setIsLoading(true);
    try {
      const res = await api
        .getInventoryData(
          isReload ? searchInventoryLocation : branchCodeSelected,
          searchProductName
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setInventoryList(res.data.data);
            setIsLoading(false);
            setIsReload(false);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onGetBranches = async () => {
    try {
      const res = await api
        .getBranch(searchBranchName)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setBranchList(res.data.data);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  const onProductDelivery = async () => {
    const body = {
      branchFromCode: branchNameFromSelected.branchCode,
      branchFromName: branchNameFromSelected.branchName,
      branchToCode: branchNameToSelected.branchCode,
      branchToName: branchNameToSelected.branchName,
      attachments: productOrders,
    };
    console.log("body", body);
    setIsOpenModal(false);
    setIsSpinner(true);
    try {
      const res = await api
        .saveSendingInvenStock(
          branchNameFromSelected.branchCode,
          branchNameFromSelected.branchName,
          branchNameToSelected.branchCode,
          branchNameToSelected.branchName,
          productOrders
        )
        .then(async (res) => {
          if (res.statusCode === 200) {
            setIsSpinner(false);
            onSuccessSubmit();
          } else {
            setIsOpenModal(true);
            onError(res.message);
          }
        })
        .catch((error) => {
          setIsOpenModal(true);
          onError(error.message);
        });
    } catch (error) {
      setIsOpenModal(true);
      onError(error.message);
    }
  };

  const onGetDetailTransaction = async (data) => {
    try {
      const res = await api
        .getDetailTransaction(data.id)
        .then(async (res) => {
          if (res.statusCode === 200) {
            setDetailTransaction(res.data);
            setTransactionSelected(data);
            setIsVisibleDetail(true);
          } else {
            onError(res.message);
          }
        })
        .catch((error) => {
          onError(error.message);
        });
    } catch (error) {
      onError(error.message);
    }
  };

  // ==================

  useEffect(() => {
    if (isReload) {
      onGetInventory();
    }
  }, [isReload]);

  useEffect(() => {
    onGetBranches();
  }, [searchBranchName]);

  useEffect(() => {
    onGetInventory();
  }, [branchCodeSelected, searchProductName]);

  const onSuccessSubmit = async () => {
    setBranchCodeSelected("");
    setProductOrders([]);
    setBranchNameFromSelected([]);
    setSearchBranchName("");
    setBranchNameToSelected([]);
    setSearchBranchName("");
    setIsLoading(false);
    setIsOpenModal(false);
    setAlertType("success");
    setMessage("Successfully...");
    setIsReload(true);
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setBranchCodeSelected("");
    setProductOrders([]);
    setBranchNameFromSelected([]);
    setSearchBranchName("");
    setBranchNameToSelected([]);
    setSearchBranchName("");
    setErrors({});
    setIsOpenModal(false);
  };

  const onError = async (message) => {
    setIsReload(false);
    setIsLoading(false);
    setIsSpinner(false);
    setIsOpen(true);
    setAlertType("error");
    setMessage(message);
  };

  const handleChangePage = (event, newPage) => {
    alert("next");
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert("page select");
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddProduct = () => {
    setSearchProductName("");
    setProductOrders([
      ...productOrders,
      {
        basePrice: "0",
        branchCode: "",
        branchName: "",
        brandId: "",
        brandName: "",
        categoryId: "",
        categoryName: "",
        createdDate: "",
        imageUrl: "",
        minQuantity: "",
        productCode: "",
        productId: "",
        productName: "",
        productSku: "",
        quantity: "0",
        status: 0,
        transferedQuantity: "",
        trxPrice: "0",
        updatedDate: "",
        masterProductCode: "",
        variantName: "",
      },
    ]);
  };

  const onHandleProductItemSelected = (currentIndex, itemSelector) => {
    const newProducts = [...productOrders];
    newProducts[currentIndex].id = itemSelector.id;
    newProducts[currentIndex].basePrice = itemSelector.basePrice;
    newProducts[currentIndex].branchCode = branchNameFromSelected.branchCode;
    newProducts[currentIndex].branchName = branchNameFromSelected.branchName;
    newProducts[currentIndex].brandId = itemSelector.brandId;
    newProducts[currentIndex].brandName = itemSelector.brandName;
    newProducts[currentIndex].categoryId = itemSelector.categoryId;
    newProducts[currentIndex].categoryName = itemSelector.categoryName;
    newProducts[currentIndex].createdDate = itemSelector.createdDate;
    newProducts[currentIndex].imageUrl = itemSelector.imageUrl;
    newProducts[currentIndex].minQuantity = itemSelector.minQuantity;
    newProducts[currentIndex].productCode = itemSelector.productCode;
    newProducts[currentIndex].productId = itemSelector.productId;
    newProducts[currentIndex].productName = itemSelector.productName;
    newProducts[currentIndex].productSku = itemSelector.productSku;
    newProducts[currentIndex].quantity = itemSelector.quantity;
    newProducts[currentIndex].updatedDate = itemSelector.updatedDate;
    newProducts[currentIndex].status = itemSelector.status;
    newProducts[currentIndex].masterProductCode =
      itemSelector.masterProductCode;
    newProducts[currentIndex].variantName = itemSelector.variantName;
    setProductOrders(newProducts);
  };

  const handleInputChange = (event, index) => {
    const newProducts = [...productOrders];
    newProducts[index][event.target.name] = event.target.value;
    setProductOrders(newProducts);
  };

  const calculateTotalPrice = (index) => {
    const newProducts = [...productOrders];
    newProducts[index].trxPrice =
      newProducts[index].transferedQuantity * newProducts[index].basePrice;
    setProductOrders(newProducts);
  };

  const onHandleRemoveProductOrder = (index) => {
    const newArray = [...productOrders];
    newArray.splice(index, 1);
    setProductOrders(newArray);
    setSearchProductName("");
  };

  const validateForm = () => {
    const newErrors = {};
    if (
      !branchNameFromSelected ||
      branchNameFromSelected === null ||
      branchNameFromSelected.length === 0
    ) {
      newErrors.branchNameFromSelected =
        "Branch / Outlet sender is required to choose";
    }

    if (
      !branchNameToSelected ||
      branchNameToSelected === null ||
      branchNameToSelected.length === 0
    ) {
      newErrors.branchNameToSelected =
        "Branch / Outlet receiver is required to choose";
    }
    return newErrors;
  };

  const validateFormProductOrder = () => {
    const hasEmptyFields = productOrders.some(
      (productItem) =>
        !productItem.masterProductCode ||
        !productItem.quantity ||
        !productItem.transferedQuantity
    );

    if (productOrders.length === 0) {
      onError("Product Order must be added first...");
      return false;
    }

    if (hasEmptyFields) {
      onError(
        "Please ensure all product order have Product Variant, Quantity, Delivery Quantity."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const isValid = validateFormProductOrder();
    if (isValid) {
      await onProductDelivery();
    }
  };

  const handlePreviewDetail = (itemSelected, indexSelected) => {
    onGetDetailTransaction(itemSelected);
  };

  // ===============
  const breadcrumbs = [
    <Typography key="3" color="text.primary" fontSize={12}>
      Transfer Stock
    </Typography>,
  ];

  return (
    <div className="max-w-[94%] flex flex-col m-auto">
      <Spinner isShowSpinner={isSpinner} />
      <div className="flex w-full h-16 mt-6 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
        <div className="flex flex-row w-full px-6 justify-between items-center">
          <div className="">
            <h3 className="text-xl font-semibold">Transfer Stock</h3>
          </div>
          <div className="">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-4 bg-white rounded-md shadow-[0_8px_30px_rgb(0,0,0,0.12)] mt-4">
        <div className="flex flex-row w-full px-6 mb-4 justify-between items-center">
          <h3 className="text-lg font-semibold">History Transfer Stock List</h3>
          <Button
            component="label"
            variant="outlined"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Transfer Stock
          </Button>
        </div>

        {/* search */}
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            marginLeft: 2,
            maxWidth: "96%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <PickerSearch
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setSearchInventoryLocation(v.branchCode);
                  setSearchBranchName("");
                } else {
                  setSearchInventoryLocation("");
                  setSearchBranchName("");
                  setIsReload(true);
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Outlet"}
              placeholderPicker={"search outlet"}
              sizePicker={"small"}
              isVisibleLabelPicker={true}
              labelTitlePicker={"CHOOSE OUTLET"}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              component="label"
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => {
                setIsReload(true);
              }}
              sx={{ marginTop: 3 }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {/* table list */}
        <TableList
          tableStructure="transfer-list"
          tableName={"History Transfer List"}
          isShowPagination={true}
          isLoading={isLoading}
          tableHeadList={tableHeadStockIn}
          data={inventoryList}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          onPreviewDetail={handlePreviewDetail}
        />
      </div>

      <Modal
        fullScreen={true}
        modalTitle={"Transfer Stock"}
        isVisible={isOpenModal}
        buttonSaveTitle={"Save Transfer Stock"}
        onPressCancel={() => {
          onCloseModal();
        }}
        onPressSave={() => handleSubmit()}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <PickerSearch
              value={branchNameFromSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameFromSelected(v);
                  setBranchCodeSelected(v.branchCode);
                  setSearchBranchName("");
                  setProductOrders([]);
                  setBranchNameToSelected([]);
                } else {
                  setProductOrders([]);
                  setBranchNameFromSelected([]);
                  setBranchNameToSelected([]);
                  setSearchBranchName("");
                  setBranchCodeSelected("");
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              labelPicker={"Choose Branch / Outlet Sender"}
              placeholderPicker={"search branch / outlet sender"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameFromSelected}
              helperText={errors.branchNameFromSelected}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <PickerSearch
              disabled={branchNameFromSelected?.branchCode ? false : true}
              value={branchNameToSelected}
              dataList={branchList}
              onInputChange={(event, newInputValue) => {
                setSearchBranchName(newInputValue);
              }}
              onChange={(e, v) => {
                if (v) {
                  setBranchNameToSelected(v);
                  setSearchBranchName("");
                } else {
                  setSearchBranchName("");
                  setBranchNameToSelected([]);
                }
              }}
              getOptionLabel={(branchList) => branchList.branchName || ""}
              getOptionDisabled={(option) => option === branchNameFromSelected}
              labelPicker={"Choose Branch / Outlet Receiver"}
              placeholderPicker={"search branch / outlet receiver"}
              sizePicker={"large"}
              isVisibleLabelPicker={true}
              labelTitlePicker={""}
              error={!!errors.branchNameToSelected}
              helperText={errors.branchNameToSelected}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              disabled={branchNameFromSelected?.branchCode ? false : true}
              sx={{ marginTop: "15px", marginBottom: "15px" }}
              variant="outlined"
              onClick={() => handleAddProduct()}
              startIcon={<AddCircleIcon />}
            >
              Add Product
            </Button>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TableContainer mt={1}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeadTransferProduct.map((item) => {
                      return (
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#060270",
                            color: "white",
                            width: item.size,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productOrders.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={tableHeadTransferProduct.length}
                        align="center"
                        sx={{ py: 4 }}
                      >
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <AddCircleIcon color="secondary" fontSize="large" />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {` No products found. You can add a new product using the
                      button above.`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {productOrders.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell width={"10%"}>{index + 1}</TableCell>
                            <TableCell width={"30%"}>
                              <PickerSearch
                                dataList={inventoryList}
                                onInputChange={(event, newInputValue) => {
                                  setSearchProductName(event.target.value);
                                }}
                                onChange={(e, v) => {
                                  if (v) {
                                    setProductNameSelected(v);
                                    setSearchProductName("");
                                    onHandleProductItemSelected(index, v);
                                  } else {
                                    setProductNameSelected([]);
                                    setSearchProductName("");
                                    setProductOrders((prevData) => {
                                      const updatedData = [...prevData];
                                      updatedData[index] = {
                                        ...updatedData[index],
                                        transferedQuantity: "",
                                        quantity: "0",
                                        masterProductCode: "",
                                      };
                                      return updatedData;
                                    });
                                  }
                                }}
                                getOptionLabel={(inventoryList) =>
                                  inventoryList.variantName || ""
                                }
                                labelPicker={"Choose Product Variant"}
                                placeholderPicker={"search product variant"}
                                sizePicker={"small"}
                                isVisibleLabelPicker={true}
                              />
                            </TableCell>
                            <TableCell width={"20%"}>{`${NumberFormat(
                              0,
                              item.quantity
                            )} pcs`}</TableCell>
                            <TableCell width={"30%"}>
                              <FormInput
                                type="number"
                                size="small"
                                label="Quantity"
                                name={"transferedQuantity"}
                                error={!!errors.transferedQuantity}
                                helperText={errors.transferedQuantity}
                                value={item.transferedQuantity}
                                onChange={(event) => {
                                  handleInputChange(event, index);
                                  calculateTotalPrice(index);
                                }}
                              />
                            </TableCell>
                            <TableCell width={"10%"} align="center">
                              <div className="flex flex-row gap-2 items-center justify-center">
                                <Tooltip title="Delete Variant">
                                  <IconButton
                                    onClick={() =>
                                      onHandleRemoveProductOrder(index)
                                    }
                                    size="medium"
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 1,
                                      backgroundColor: "#f4e6fb",
                                      color: "#FE3133",
                                    }}
                                  >
                                    <BsFillTrash3Fill />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Modal>

      <CustomizedSnackbars
        isOpen={isOpen}
        typeInfo={alertType}
        messageInfo={message}
        handleCloseSnackbar={() => setIsOpen(false)}
      />

      <ModalDetail
        onClose={() => setIsVisibleDetail(false)}
        isVisibleDetail={isVisibleDetail}
        transactionSelected={transactionSelected}
        detailTransaction={detailTransaction}
      />
    </div>
  );
}

export default Inventory;
