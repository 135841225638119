import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import NumberFormat from "../../helpers/functions/numberFormat";
import moment from "moment/moment";
import { FaEye } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import EmptyTableList from "./EmptyTableList";
import { MdLibraryAdd } from "react-icons/md";
import { MdLogin } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

function TableList({
  tableHeadList,
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
  tableName,
  tableStructure,
  onPreviewDetail,
  onEditData,
  onAddVariant,
  isShowPagination,
  onCreateLogin,
  onChangePassword,
}) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1866ee",
      color: theme.palette.common.white,
      fontSize: 13,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <StyledTableRow key={index}>
        {tableHeadList.map((_, i) => (
          <StyledTableCell key={_.name} component="th" scope="row">
            <Skeleton variant="rectangular" width={75} height={20} />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };

  const renderStatus = (item, index) => {
    if (item.status === 0) {
      return (
        <Typography
          variant="body2"
          color={"#FF1719"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Not Ready Sale"}
        </Typography>
      );
    } else if (item.status === 301) {
      return (
        <Typography
          variant="body2"
          color={"#FF1719"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Not For Sale"}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body2"
          color={"#007800"}
          sx={{ fontSize: 11, fontWeight: "bold" }}
        >
          {"Ready To Sale"}
        </Typography>
      );
    }
  };

  const renderDataTable = (item, index) => {
    if (tableStructure === "best-seller-products") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell component="th" scope="row">
            {index + 1}
          </StyledTableCell>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.trxPrice
          )}`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.baseprice
          )}`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netprice
          )}`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.grosprofit
          )}%`}</StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-balance") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            {item.transactionToName}
          </StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.item
          )} Items`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netPayment
          )}`}</StyledTableCell>
          <StyledTableCell align="left">
            {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-income") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            {item.transactionToName}
          </StyledTableCell>
          <StyledTableCell align="left">{item.transactionNo}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.item
          )} Items`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netPayment
          )}`}</StyledTableCell>
          <StyledTableCell align="left">
            {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-expenses") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">
            {item.transactionFromName}
          </StyledTableCell>
          <StyledTableCell align="left">
            {" "}
            {moment(item.createdDate).format("DD MMM YYYY, h:mm")}
          </StyledTableCell>
          <StyledTableCell align="left">{item.transactionType}</StyledTableCell>
          <StyledTableCell align="left">{item.note}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netPayment
          )}`}</StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "sales") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.transactionNo}</StyledTableCell>
          <StyledTableCell align="left">
            {item.transactionToName}
          </StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.item
          )} Items`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netPayment
          )}`}</StyledTableCell>
          <StyledTableCell align="left">
            {moment(item.transactionDate).format("DD MMM YYYY, h:mm")}
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "#daffda" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.statusName === "Done" ? "#007800" : "white"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "daily-profit") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">
            {" "}
            {`Rp. ${NumberFormat(0, item.trxPrice)}`}
          </StyledTableCell>
          <StyledTableCell align="left">
            {" "}
            {`Rp. ${NumberFormat(0, item.discount)}`}
          </StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.baseprice
          )}`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.netprice
          )}`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.grosprofit
          )}%`}</StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "current-stock") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.basestock
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalout
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalin
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalsel
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.finalstock
          )} pcs`}</StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "product-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: 250,
                gap: 2,
                alignItems: "center",
              }}
            >
              <img src={item.imageUrl} className="w-10 h-10 rounded-md" />
              <Typography variant="body2">{item.productName}</Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">{item.categoryName}</StyledTableCell>
          <StyledTableCell align="left">{item.brandName}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${"#daffda"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Variant">
                <IconButton
                  onClick={() => onAddVariant(item, index)}
                  size="medium"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                    color: "#FF5101",
                  }}
                >
                  <MdLibraryAdd />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "variant-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: 250,
                gap: 2,
                alignItems: "center",
              }}
            >
              <img src={item.imageUrl} className="w-10 h-10 rounded-md" />
              <Typography variant="body2">{item.variantName}</Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="left">{item.productSku}</StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "addon-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.name}</StyledTableCell>
          <StyledTableCell align="left">{item.addOnsType}</StyledTableCell>
          <StyledTableCell align="left">
            {item.branchCode ? item.branchCode : "-"}
          </StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.price
          )}`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${item.isActive ? "#daffda" : "#FFAFB0"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.isActive ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.isActive ? "Active" : "Non active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Setting Add On">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "product-receive-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.transactionNo}</StyledTableCell>
          <StyledTableCell align="left">{item.transactionType}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.item
          )} Items`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName == "Done" ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.statusName == "Done" ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stockin-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.branchName}</StyledTableCell>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.quantity
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.trxPrice
          )}`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.status === 201 ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              {renderStatus(item, index)}
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Setup Selling Price">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stockout-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.branchName}</StyledTableCell>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.quantity
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.trxPrice
          )}`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.status === 201 ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              {renderStatus(item, index)}
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "purchases-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.transactionNo}</StyledTableCell>
          <StyledTableCell align="left">{item.transactionType}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.item
          )} items`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Done" ? "#daffda" : "#FFAFB0"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.statusName == "Done" ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "transfer-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.branchName}</StyledTableCell>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.quantity
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`Rp. ${NumberFormat(
            0,
            item.trxPrice
          )}`}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${item.status !== 0 ? "#daffda" : "#FFAFB0"}`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.status !== 0 ? "#007800" : "#FF1719"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.status !== 0 ? "Done" : "In Progress"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "brand-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.brandName}</StyledTableCell>
          <StyledTableCell align="left">{item.createdDate}</StyledTableCell>
          <StyledTableCell align="left">{item.updatedDate}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "category-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.categoryName}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "stock-card-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{item.productName}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.basestock
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalout
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalin
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.totalsel
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="left">{`${NumberFormat(
            0,
            item.finalstock
          )} pcs`}</StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="See detail">
                <IconButton
                  onClick={() => onPreviewDetail(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaEye />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "supplier-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.supplierName}</StyledTableCell>
          <StyledTableCell align="left">
            {item.supplierContacName}
          </StyledTableCell>
          <StyledTableCell align="left">{item.supplierPhone}</StyledTableCell>
          <StyledTableCell align="left">{item.supplierEmail}</StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "outlet-list") {
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.branchName}</StyledTableCell>
          <StyledTableCell align="left">{item.address}</StyledTableCell>
          <StyledTableCell align="left">{item.branchPhone}</StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: `${
                  item.statusName === "Active" ? "#daffda" : "red"
                }`,
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={item.statusName === "Active" ? "#007800" : "white"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {item.statusName}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    } else if (tableStructure === "user-list") {
      const branchName = item.attachments[0];
      return (
        <StyledTableRow key={item.id}>
          <StyledTableCell align="left">{index + 1}</StyledTableCell>
          <StyledTableCell align="left">{item.username}</StyledTableCell>
          <StyledTableCell align="left">{item.userEmail}</StyledTableCell>
          <StyledTableCell align="left">
            {" "}
            {item.attachments.length !== 0
              ? branchName.branchName
              : item.branchCode}
          </StyledTableCell>
          <StyledTableCell>
            <Box
              sx={{
                backgroundColor: "#daffda",
                padding: "3px 20px 3px 20px",
                borderRadius: 8,
                display: "inline-block",
              }}
            >
              <Typography
                variant="body2"
                color={"#007800"}
                sx={{ fontSize: 11, fontWeight: "bold" }}
              >
                {"Active"}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell align="center">
            <div className="flex flex-row gap-2 items-center justify-center">
              <Tooltip title="Create Login">
                <IconButton
                  onClick={() => onCreateLogin(item, index)}
                  size="medium"
                  color="primary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <MdLogin />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit data">
                <IconButton
                  onClick={() => onEditData(item, index)}
                  size="medium"
                  color="secondary"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                  }}
                >
                  <FaPencilAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title="change password">
                <IconButton
                  onClick={() => onChangePassword(item, index)}
                  size="medium"
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: "#f4e6fb",
                    color: "#FF5101",
                  }}
                >
                  <RiLockPasswordFill />
                </IconButton>
              </Tooltip>
            </div>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
  };

  return (
    <div className="py-3 px-6 flex items-center">
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableHeadList.map((tableHead) => {
                  return (
                    <StyledTableCell align={tableHead.tableAlign}>
                      {tableHead.name}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRowsLoader rowsNum={5} />
              ) : data?.length === 0 ? (
                <EmptyTableList
                  colSpan={tableHeadList.length}
                  tableName={tableName}
                />
              ) : (
                <>{data?.map((item, index) => renderDataTable(item, index))}</>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isShowPagination ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </div>
  );
}

export default TableList;
